<template>
  <AdminView>
    <h1 class="title-1">Matériel</h1>

    <div class="search">
      <Input title="Rechercher" v-model="search" />
    </div>

    <EquipmentForm mode="add" @addEquipment="addEquipment" />

    <EquipmentForm
      v-for="equipment in filteredEquipments"
      :key="equipment.id"
      :equipment="equipment"
      @editEquipment="editEquipment"
      @removeEquipment="openModal"
      mode="edit"
    />
    <ConfirmationModal
      v-if="isOpenDeleteModal"
      @close="isOpenDeleteModal = false"
      @confirm="remove"
    />
  </AdminView>
</template>

<script>
import { mapGetters } from "vuex"
import AdminView from "@/components/AdminView"
import EquipmentForm from "@/components/Forms/EquipmentForm"
import Input from "@/components/Forms/Fields/Input"
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal.vue"

export default {
  name: "EquipmentsList",
  components: {
    AdminView,
    EquipmentForm,
    Input,
    ConfirmationModal
  },
  data() {
    return {
      search: "",
      isOpenDeleteModal: false,
      idToDelete: 0
    }
  },
  async created() {
    await this.$store.dispatch("admin/getEquipments")
  },
  computed: {
    ...mapGetters({
      equipments: "admin/equipments"
    }),
    filteredEquipments() {
      return this.equipments.filter((p) =>
        p.name.toLowerCase().includes(this.search.toLowerCase())
      )
    }
  },
  methods: {
    async remove() {
      await this.$store.dispatch("admin/removeEquipment", this.idToDelete)
      this.isOpenDeleteModal = false
    },
    openModal(id) {
      this.idToDelete = id
      this.isOpenDeleteModal = true
    },
    async addEquipment(data) {
      await this.$store.dispatch("admin/addEquipment", data)
    },
    async editEquipment(name, id) {
      await this.$store.dispatch("admin/editEquipment", { name, id })
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  margin-bottom: 20px;

  > div {
    flex-grow: 0;
  }
}
</style>
