<template>
  <div class="pathology-form">
    <Input class="title" v-model="equipmentName" />

    <Button
      v-if="edited && mode === 'edit'"
      title="Modifier"
      type="primary"
      @click="editEquipment"
    />

    <Button
      v-if="mode === 'edit'"
      title="Supprimer"
      type="warning"
      @click="removeEquipment"
    />

    <Button v-if="mode === 'add'" title="Ajouter" @click="addEquipment" type="primary">
      <SVGPlus />
    </Button>
  </div>
</template>

<script>
import _ from "lodash"
import Input from "@/components/Forms/Fields/Input"
import Button from "@/components/Button"
import SVGPlus from "@/assets/icons/plus.svg"

export default {
  name: "EquipmentForm",
  props: {
    equipment: {
      type: Object,
      default() {
        return {
          name: ""
        }
      }
    },
    mode: {
      type: String,
      default: "edit"
    }
  },
  components: {
    Input,
    Button,
    SVGPlus
  },
  data() {
    return {
      edited: false,
      editableEquipment: {}
    }
  },
  created() {
    this.editableEquipment = _.cloneDeep(this.equipment)
  },
  methods: {
    removeEquipment() {
      this.$emit("removeEquipment", this.editableEquipment.id)
    },
    addEquipment() {
      this.$emit("addEquipment", this.editableEquipment.name)
      this.editableEquipment.name = ""
    },
    editEquipment() {
      this.$emit("editEquipment", this.editableEquipment.name, this.editableEquipment.id)
    }
  },
  computed: {
    equipmentName: {
      get() {
        return this.editableEquipment.name
      },
      set(name) {
        this.editableEquipment.name = name
      }
    }
  },
  watch: {
    equipmentName() {
      this.edited = true
    }
  }
}
</script>

<style lang="scss" scoped>
.pathology-form {
  display: flex;
  align-items: center;

  .title {
    flex-grow: 1;
  }
}
</style>
